export const environment = {
    production: true,
    GOOGLE_CLIENT_ID: '62198175306-h477rqb0lmglnlolib9pn2m9pjudrm2h.apps.googleusercontent.com',
    ROOT_URL: 'https://drstrange.fyle.tech',
    APP_URL: 'https://drstrange1.fyle.tech/app',
    APP_ROUTER_URL: 'https://drstrange-accounts.fyle.tech',
    ROUTER_URL: 'https://drstrange-accounts.fyle.tech',
    SENTRY_DSN: 'https://330ed2d3f658419887b27e307075d220@sentry.io/2461658',
    SEND_ERROR_TO_SENTRY: false,
    SENTRY_TRACES_SAMPLE_RATE: 0.2,
    ENVIRONMENT: 'drstrange',
    RELEASE: 'b59dafdf256ae09fb5735b61b8e66f5a473313a4',
    TARGET: 'fyle',
    GOOGLE_MAPS_API_KEY: 'AIzaSyAN1rmM-KdfNimjKxUwRkQB2ASpY6Czrvc',
    DWOLLA_ENVIRONMENT: 'sandbox',
    LAUNCH_DARKLY_CLIENT_ID: '61fb848da00c1016c73fbe54',
    NETLIFY_FYLE_APP_KEY: 'undefined',
    NETLIFY_FUNCTION_URL: 'undefined',
    DEPLOY_LOGS_WEBHOOK_URL: 'undefined',
    C1_DEPLOY_LOGS_WEBHOOK_URL: 'undefined',
    SLACK_COPILOT_FEEDBACK_WEBHOOK: 'undefined',
    NETLIFY_ACCESS_TOKEN: 'undefined',
    MIXPANEL_PROJECT_TOKEN: 'dce787f3a74f5ede8264af230a4b4652',
    MIXPANEL_PROXY_URL: '',
    USE_MIXPANEL_PROXY: 'undefined',
    ENABLE_MIXPANEL: 'undefined',
    YODLEE_FAST_LINK_URL: 'undefined',
  };